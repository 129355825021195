export const initialProject = {
  description: "",
  id: 0,
  inserted_at: "",
  status: 0,
  uid: "",
  updated_at: "",
  user_id: 0
}

export const parseProxyData = (data) => JSON.parse(JSON.stringify(data));

export const clearContentButtons = (text)=> {
  const regex = /!\[button:.*?\]\s*\(.*?\)/g;
  return text.replace(regex, '');
}

export const parseContentCitations = ({ content, citations = [] }) => {
  if(citations.length === 0) {
    return content
  }

  let processedContent = content;
  citations.forEach(citation => {
    const regex = new RegExp(`\\[${citation.number}\\]`, 'g');
    processedContent = processedContent.replace(regex, ` [${citation.number}](${citation.url})`);
  });

  return processedContent;
}

export const getImageUrl = (text) => {
  const urlRegex = /\[(https?:\/\/[^\]]+)\]/;
  const result = text.match(urlRegex);
  if (result && result[1]) {
    return `![url](${result[1]})`;
  } else {
    return '';
  }
}

export const getCleanText = (text) => {
  const cleanButtonText = clearContentButtons(text)
  const cleanRegex = /\[https?:\/\/[^\]]+\]/;
  return cleanButtonText.replace(cleanRegex, '').trim();
}
